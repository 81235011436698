import React, { useEffect, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { post } from '../../../../src/util/api';
import * as validators from '../../../util/validators';
import { Button, FieldTextInput } from '../../../components';

import formImage from './formImage.png';
import css from './RegisterForm.module.css';

const RegisterForm = props => {
    const { intl } = props;
    const [shouldWait, setShouldWait] = useState(true);
    const [isRegistered, setIsRegistered] = useState(false);

    const onSubmit = values => {
        const requestOperatorOptions = {
            subject: intl.formatMessage({ id: 'RegisterForm.emailSubject' }),
            text: `<p>${intl.formatMessage({ id: 'RegisterForm.emailBodyDetails' })}</p>
          <p>${intl.formatMessage({ id: 'RegisterForm.name' })}: ${values.userName}</p>
          <p>${intl.formatMessage({ id: 'RegisterForm.email' })}: ${values.userEmail.trim()}</p>
          `,
        };

        const requestOptions = {
            recipient: values.userEmail.trim(),
            subject: intl.formatMessage({ id: 'RegisterForm.emailSubject' }),
            text: `<p>${intl.formatMessage({ id: 'RegisterForm.thankYou' })}</p>
          <p>${intl.formatMessage({ id: 'RegisterForm.name' })}: ${values.userName}</p>
          <p>${intl.formatMessage({ id: 'RegisterForm.email' })}: ${values.userEmail.trim()}</p>
          `,
        };

        post('/api/sendgrid-operator-email', requestOperatorOptions)
            .then(response => {
                post('/api/sendgrid-email', requestOptions)
                    .then(response => {
                        setIsRegistered(true);
                    })
            })
            .catch(e => {
            });
    };

    const required = validators.required(intl.formatMessage({ id: 'RegisterForm.required' }));
    const valid = validators.emailFormatValid(intl.formatMessage({ id: 'RegisterForm.invalid' }));

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            setShouldWait(false);
        }, 500);

        return () => clearTimeout(timeOutId);
    }, []);

    if (shouldWait) {
        return null;
    }

    return (
        <div className={css.root}>
            <img src={formImage} className={css.image} />
            <div className={css.wrapper}>
                {isRegistered ? (
                    <div className={css.content}>
                        <p className={css.textBig}>
                            <FormattedMessage id="RegisterForm.thankYou" />
                        </p>
                        <p className={css.text}>
                            <FormattedMessage id="RegisterForm.lookingForward" />
                        </p>
                    </div>
                ) : (
                    <FinalForm
                        onSubmit={onSubmit}
                        render={fieldRenderProps => {
                            const { handleSubmit, invalid, pristine, submitting } = fieldRenderProps;
                            const submitDisabled = invalid || pristine || submitting;

                            return (
                                <form
                                    className={css.content}
                                    onSubmit={e => {
                                        e.preventDefault();
                                        handleSubmit(e);
                                    }}
                                >
                                    <p className={css.label}>
                                        <FormattedMessage id="RegisterForm.launchingSoon" />
                                    </p>

                                    <div className={css.fieldWrapper}>
                                        <FieldTextInput
                                            inputRootClass={css.inputClass}
                                            className={css.field}
                                            type="text"
                                            id="userName"
                                            name="userName"
                                            placeholder={intl.formatMessage({ id: 'RegisterForm.namePlaceholder' })}
                                            validate={required}
                                        />

                                        <FieldTextInput
                                            inputRootClass={css.inputClass}
                                            className={css.field}
                                            type="email"
                                            id="userEmail"
                                            name="userEmail"
                                            placeholder={intl.formatMessage({ id: 'RegisterForm.emailPlaceholder' })}
                                            validate={validators.composeValidators(required, valid)}
                                        />
                                    </div>

                                    <Button className={css.submitButton} type="submit" disabled={submitDisabled}>
                                        <FormattedMessage id='RegisterForm.submit' />
                                    </Button>
                                </form>
                            );
                        }}
                    />
                )}
            </div>
        </div >
    );
};

export default RegisterForm;