import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import {
  Button,
  LayoutSingleColumn,
  Modal,
  Page
} from '../../components';
import RegisterForm from './RegisterForm/RegisterForm';

import coverVideo from './only_coming_soon_2.mp4';
import CloseIcon from './close.png';
import css from './UnauthedLandingPage.module.css';

export const UnauthedLandingPageComponent = props => {
  const {
    history,
    intl,
    onManageDisableScrolling,
    location,
    scrollingDisabled,
    currentUserListing,
    currentUserListingFetched,
    pageAssetsData,
    inProgress,
    error,
  } = props;

  const [earlyAccesModalIsOpen, setEarlyAccesModalIsOpen] = useState(false);

  const title = intl.formatMessage({ id: 'UnauthedLandingPage.title' });

  return (
    <Page
      // title={title}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSingleColumn
        topbar={<TopbarContainer prelaunchTopbar={true} />}
      >
        <div className={css.root}>
          <video className={css.backgroundVideo}
            autoPlay
            loop
            muted
          >
            <source src={coverVideo} type="video/mp4" />
          </video>
          <div class={css.overlay} />
          <div className={css.centerSection}>
            <h1 className={css.title}>
              <FormattedMessage id="UnauthedLandingPage.title" />
            </h1>
            <p className={css.info}>
              <FormattedMessage id="UnauthedLandingPage.info" />
            </p>

            <Button className={css.button} onClick={() => setEarlyAccesModalIsOpen(true)}>
              <FormattedMessage id="UnauthedLandingPage.ctaButton" />
              <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 4L0.5 8L0.5 0L6.5 4Z" fill="var(--marketplaceColor)" />
              </svg>
            </Button>
          </div>

          <div className={css.scrollingTextWrapper}>
            <div className={css.scrollingTextContainer}>
              <p className={css.scrollingText}>
                Buy | Sell | Grow – Elevate Your Business - Discover, Trade & Thrive in the Luxury Market
              </p>
              <p className={css.scrollingText}>
                Buy | Sell | Grow – Elevate Your Business - Discover, Trade & Thrive in the Luxury Market
              </p>
            </div>
          </div>

          <Modal
            id={"EarlyAccesFormModal"}
            containerClassName={css.modalContainer}
            contentClassName={css.modalContent}
            scrollLayerClassName={css.scrollLayer}
            isOpen={earlyAccesModalIsOpen}
            onClose={() => {
              setEarlyAccesModalIsOpen(false);
            }}
            onManageDisableScrolling={onManageDisableScrolling}
            closeButtonMessage={true}
            closeButtonIcon={<img src={CloseIcon} className={css.closeIcon} />}
            usePortal
          >
            <RegisterForm intl={intl} />
          </Modal>
        </div>

        {/* <div className={css.centerSection}>
        </div> */}
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched } = state.user;
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    pageAssetsData,
    inProgress,
    error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const UnauthedLandingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(UnauthedLandingPageComponent);

export default UnauthedLandingPage;
